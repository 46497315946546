import React from "react";
import { Layout, PortfolioNav, PortfolioHeader } from "../../../components";
import locales from "../../../constants";

const Alingua = ({ pageContext: { slug } }) => {
  const nav = {
    initialSlideIndex: 1,
  };
  const lang = "en";
  return (
    <Layout
      seo={{
        title: "Alingua",
        href: slug,
        lang: "en",
        ogImage: require("../../../assets/img/portfolio/alingua-og-image.jpg"),
      }}
      header={{
        background:
          "linear-gradient( 45deg, #003061, #032b54, #02264a, #012242, #011b36 )",
        icons: "#003061",
        navClass: "alingua",
        ogImage: require("../../../assets/img/portfolio/alingua_top_back.jpg"),
      }}
      langSwitch={{
        langKey: "pl",
        langSlug: "/projekty/alingua/",
      }}
    >
      <PortfolioHeader name="alingua" height="70" />
      <section className="container-fluid alingua-section-1" id="info">
        <div className="row">
          <div className="col-md-6">
            <div className="inner">
              <h1>Alingua</h1>
              <ul>
                <li>Rebranding</li>
                <li>Website</li>
                <li>E-mail signatures</li>
                <li>Ongoing website administration and support</li>
              </ul>
            </div>
          </div>
          <div className="col-md-6">
            <div className="inner">
              <p>
                We have been cooperating with the Alingua Translation Agency for
                nearly 5 years. During this time, as part of our cooperation, we
                developed two website designs (the previous and the present one)
                based on many hours of UX/UI workshops. We have also created a
                coherent corporate identity for all of the company's
                international marketing activities. In all marketing materials
                Alingua wanted to reflect its philosophy, based on very high
                quality translations, above-average customer service standard,
                and a work system developed over the years, compliant with three
                ISO standards. The final goal was to establish wider cooperation
                with global and prestigious brands.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid alingua-section-2">
        <div className="row">
          <div className="col-md-7">
            <div className="color-scheme"></div>
            <img
              src={require("../../../assets/img/portfolio/alingua_top_logo.png")}
              className="img-fluid"
              alt=""
            />
          </div>
          <div className="col-md-5">
            <h3>Logo</h3>
            <div className="row">
              <div className="col-sm-6">
                <p>Blue CMYK:</p>
                <h5>
                  <span>100</span>
                  <span>60</span>
                  <span>0</span>
                  <span>55</span>
                </h5>
              </div>
              <div className="col-sm-6">
                <p>Orange CMYK:</p>
                <h5>
                  <span>0</span>
                  <span>55</span>
                  <span>80</span>
                  <span>5</span>
                </h5>
              </div>
              <div className="col-sm-6">
                <p>RGB</p>
                <h5>
                  <span>0</span>
                  <span>47</span>
                  <span>97</span>
                </h5>
              </div>
              <div className="col-sm-6">
                <p>RGB</p>
                <h5>
                  <span>232</span>
                  <span>132</span>
                  <span>57</span>
                </h5>
              </div>
              <div className="col-sm-6">
                <p>HEX</p>
                <h5>002f61</h5>
              </div>
              <div className="col-sm-6">
                <p>HEX</p>
                <h5>e88439</h5>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid alingua-section-3">
        <div className="row">
          <div className="col-md-5">
            <div className="inner">
              <h3>Logo</h3>
              <p>
                Entering new markets was connected with the company's new
                marketing needs and required development of a set of promotional
                and informational materials. This, in turn, required ordering
                and refreshing the visual identification of the Company. We
                rebranded the entire brand, designed a new logo and visual
                identification.
              </p>
            </div>
          </div>
          <div className="col-md-7">
            <img
              src={require("../../../assets/img/portfolio/alingua_main_1.png")}
              className="img-fluid"
              alt=""
            />
          </div>
        </div>
      </section>

      <section className="container-fluid alingua-section-4">
        <div className="row">
          <div className="col-md-12 text-center">
            <div className="scroll_wrapper">
              <div className="img_wrapper">
                <img
                  src={require("../../../assets/img/portfolio/alingua_entire_page_en.png")}
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid alingua-section-5">
        <div className="row">
          <div className="col-lg-5">
            <div className="inner">
              <h3>Website</h3>
              <p>
                The new website had to meet a number of assumptions set by
                Alingua and developed at the stage of UX / UI workshops, i.e. an
                extensive order form (allowing a very quick quote), enable easy
                introduction of subsequent language versions, and present
                content in a manner tailored to the appropriate target groups on
                domestic and foreign markets.
              </p>
              <br />
              <a
                href="https://alingua.pl/en"
                target="_blank"
                rel="noopener noreferrer nofollow"
                className="btn_custom"
              >
                See Alingua's new website
              </a>
            </div>
          </div>
          <div className="col-lg-7 text-right">
            <img
              src={require("../../../assets/img/portfolio/alingua_main_2_en.png")}
              className="img-fluid"
              alt=""
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <img
              src={require("../../../assets/img/portfolio/alingua_main_3_en.png")}
              className="img-fluid"
              alt=""
            />
          </div>
          <div className="col-lg-6">
            <img
              src={require("../../../assets/img/portfolio/alingua_main_4_en.png")}
              className="img-fluid"
              alt=""
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <img
              src={require("../../../assets/img/portfolio/alingua_main_5_en.png")}
              className="img-fluid"
              alt=""
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <img
              src={require("../../../assets/img/portfolio/alingua_main_6_en.png")}
              className="img-fluid"
              alt=""
            />
          </div>
          <div className="col-lg-6">
            <img
              src={require("../../../assets/img/portfolio/alingua_main_7_en.png")}
              className="img-fluid"
              alt=""
            />
          </div>
        </div>
      </section>

      <section className="container-fluid alingua-section-6">
        <div className="row">
          <div className="col-md-6">
            <img
              src={require("../../../assets/img/portfolio/alingua_main_8.png")}
              className="img-fluid"
              alt=""
            />
          </div>
          <div className="offset-md-1 col-md-5">
            <div className="inner">
              <h3>E-mail signatures</h3>
              <p>
                We have also designed e-mail signatures, so that the Company's
                daily correspondence stays consistent with the visual
                identification of the entire brand and shows potential
                contractors that Alingua always takes care of even the smallest
                details.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid alingua-section-7">
        <div className="inner">
          <h3>Ongoing website administration and support</h3>
          <p>
            We are currently managing the company's website as part of our
            ForSecure service{" "}
            <a
              href="https://forsecure.pl/"
              target="_blank"
              rel="noreferrer noopener nofollow"
            >
              (www.forsecure.pl)
            </a>
            , which is associated with its regular updates, security, as well as
            ongoing maintenance, and further development of the website with new
            functionalities at the request of our Partner.
          </p>
          <img
            src={require("../../../assets/img/forsecure.png")}
            className="img-fluid"
            alt=""
          />
        </div>
      </section>

      <section className="container-fluid alingua-section-8">
        <div className="row">
          <div className="offset-lg-2 col-lg-2 offset-md-1 col-md-3 photo-wrapper">
            <img
              src={require("../../../assets/img/portfolio/alingua_reference_photo.png")}
              className="img-fluid"
              alt=""
            />
          </div>
          <div className="offset-md-1 col-md-6">
            <div className="inner">
              <h3>References</h3>

              <p>
                The projects developed by the company are consistent with their
                unchanging commitment. Full availability of the employees giving
                us support in any challenging situations is an important asset
                for us. A kind and open attitude to customer requirements
                encourages us to continue our cooperation.
              </p>

              <p>
                <strong>Piotr Gabor</strong>
                <br />
                Co-Founder & Member of the Board
                <br />
                Alinqua
              </p>
            </div>
          </div>
        </div>
      </section>
      <PortfolioNav data={nav} locales={locales[lang]} />
    </Layout>
  );
};
export default Alingua;
